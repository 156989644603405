import "core-js/modules/es.error.cause.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref, watch } from 'vue';
import { modifyPassword } from '@/api/admin';
import { ElMessage } from 'element-plus';
import i18n from '@/i18n';
import jsMd5 from 'js-md5';
export default {
  __name: 'update_password',
  props: {
    dialogTitle: {
      type: String,
      default: '',
      required: true
    },
    dialogTableValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:modelValue', 'initPackagesList'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const formRef = ref(null);
    const form = ref({
      old_password: '',
      new_password: '',
      confirm_password: ''
    });
    const form2 = ref({
      old_password: '',
      new_password: ''
    });
    const rules = ref({
      old_password: [{
        required: true,
        message: '请输入旧密码',
        trigger: 'blur'
      }],
      new_password: [{
        required: true,
        message: '请输入新密码',
        trigger: 'blur'
      }],
      confirm_password: [{
        required: true,
        message: '请确认新密码',
        trigger: 'blur'
      }, {
        validator: (rule, value, callback) => {
          if (value !== form.value.new_password) {
            callback(new Error('两次输入的密码不一致'));
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }]
    });
    watch(() => props.dialogTableValue, () => {
      console.log(props.dialogTableValue);
      form.value = props.dialogTableValue;
    }, {
      deep: true,
      immediate: true
    });
    const emits = __emit;
    const handleClose = () => {
      emits('update:modelValue', false);
    };
    const handleConfirm = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          form2.value.old_password = jsMd5.md5(form.value.old_password);
          form2.value.new_password = jsMd5.md5(form.value.new_password);
          await modifyPassword(form2.value);
          ElMessage({
            message: i18n.global.t('message.updeteSuccess'),
            type: 'success'
          });
          emits('initPackagesList');
          handleClose();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        title: __props.dialogTitle,
        width: "40%",
        onClose: handleClose
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: handleClose
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: handleConfirm
        }, {
          default: _withCtx(() => [_createTextVNode("确认")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: form.value,
          "label-width": "120px",
          rules: rules.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "请输入旧密码",
            prop: "old_password"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.old_password,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.value.old_password = $event),
              type: "password",
              "show-password": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "请输入新密码",
            prop: "new_password"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.new_password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.value.new_password = $event),
              type: "password",
              "show-password": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "请确认新密码",
            prop: "confirm_password"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.confirm_password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.value.confirm_password = $event),
              type: "password",
              "show-password": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title"]);
    };
  }
};